<template>
  <b-row class="mt-1 match-height">
      <b-col xl="6" lg="6" md="10" offset-xl="3" offset-lg="3" offset-md="1">
          <b-card>
            <b-card-body>
              <b-alert v-if="epaycoResponseDetails.investment.epaycoTransactionStatus=='1'" variant="primary" show><div class="alert-body"><span><strong>PAGO APROBADO</strong></span></div></b-alert>
              <b-alert v-if="epaycoResponseDetails.investment.epaycoTransactionStatus=='3'" variant="warning" show><div class="alert-body"><span><strong>PAGO PENDIENTE</strong></span></div></b-alert>
              <b-alert v-if="epaycoResponseDetails.investment.epaycoTransactionStatus=='2' || epaycoResponseDetails.investment.epaycoTransactionStatus=='4'" variant="danger" show><div class="alert-body"><span><strong>PAGO RECHAZADO</strong></span></div></b-alert>
              <b-alert variant="secondary" show><div class="alert-body"><span><strong>Por seguridad le recomendamos guardar la siguiente información:</strong></span></div></b-alert>
            </b-card-body>
            <b-card-body>
              <b-row>
                <b-col md="12" lg="12">
                  <table class="table b-table table-striped">
                    <tbody>
                      <tr>
                        <td>Referencia de Pago</td>
                        <td>{{epaycoResponseDetails.investment.epaycoTransactionRef}}</td>
                      </tr>
                      <tr>
                        <td>Fecha</td>
                        <td>{{epaycoResponseDetails.investment.createDate}}</td>
                      </tr>
                      <tr>
                        <td>Monto Invertido</td>
                        <td>{{epaycoResponseDetails.investment.amountInvested.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</td>
                      </tr>                  
                      <tr>
                        <td>Proyecto</td>
                        <td>{{epaycoResponseDetails.project.projectCode}}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{epaycoResponseDetails.profileDetails.contactEmail}}</td>
                      </tr>
                    </tbody>                
                  </table>
                </b-col>                
              </b-row>
              <b-row class="mt-2">
                <b-col md="12" lg="12">
                  <b-alert v-if="epaycoResponseDetails.investment.epaycoTransactionStatus=='1'" variant="primary" show><div class="alert-body"><span><strong>Su pago fue confirmado. Gracias por confiar en nosotros. En minutos su inversión estará disponible en el módulo "Mis Inversiones".</strong></span></div></b-alert>
                  <b-alert v-if="epaycoResponseDetails.investment.epaycoTransactionStatus=='3'" variant="warning" show><div class="alert-body"><span><strong>Su pago está pendiente por confirmar. Este proceso puede tomar varios minutos. Si en una hora aún no está disponible su inversión y el dinero fue descontado de su cuenta, por favor entre en contacto.</strong></span></div></b-alert>
                  <b-alert v-if="epaycoResponseDetails.investment.epaycoTransactionStatus=='2' || epaycoResponseDetails.investment.epaycoTransactionStatus=='4'" variant="danger" show><div class="alert-body"><span><strong>Su pago fue rechazado. Lo invitamos a intentarlo nuevamente. Si tiene problemas realizando el pago vía PSE por favor contáctenos.</strong></span></div></b-alert>
                </b-col>                 
              </b-row>
              <b-row class="mt-2">
                <b-col v-if="!processing" md="12" lg="12">
                  <b-button vsize="lg" variant="primary" block @click="Continue">Continuar</b-button>
                </b-col>   
                <b-col v-if="processing" md="12" lg="12">
                  <b-button size="lg" variant="primary" disabled block><b-spinner small label="Procesando..." /></b-button>
                </b-col>               
              </b-row>
              
            </b-card-body>
          </b-card>
      </b-col>
    </b-row> 
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {  BRow, BCol, BCard, BCardText, BLink, BButton, BAlert, BSpinner } from 'bootstrap-vue'


export default {
  components: {
    BRow, BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BAlert,
    BSpinner,
  },
  data() {
    return {
      userType: '',
      test: false,
      investorID: null,
      thereAreInvestments: null,
      ref_epayco: null,
      epaycoResponseDetails: null,
      processing: false,
    }
  },
  created() {
    var userData = getUserData(); 
    this.userType = userData.userType;
    this.investorID = userData.userID;

    console.log(this.$route);

    if(this.$route != null && this.$route.query != null && this.$route.query.ref_payco != null) {
      this.ref_epayco = this.$route.query.ref_payco;
      console.log("ref_epayco: " + this.$route.query.ref_payco);
      this.GetEpaycoPaymentResponse();
    }

  },
  methods: {
    GetEpaycoPaymentResponse(){ 
      this.processing = true;
      axios.get('/Payments/GetEpaycoResponse', { params: { refEpayco: this.ref_epayco }})
      .then(response =>  {      
        this.epaycoResponseDetails = response.data;
        console.log(this.epaycoResponseDetails);
        this.processing = false;
      })
      .catch(error => {
        this.processing = false;
      })
    },  
    Continue(){ 
      if(this.epaycoResponseDetails.investment.epaycoTransactionStatus=='1'){
        this.$router.push('profile-definition?userID=' + this.epaycoResponseDetails.profileDetails.userID);
      } else if(this.epaycoResponseDetails.investment.epaycoTransactionStatus=='2' || this.epaycoResponseDetails.investment.epaycoTransactionStatus=='4' ) {
        this.$router.push('invest-in-project?projectCode=' + this.epaycoResponseDetails.project.projectCode + "&amountToInvest=" + this.epaycoResponseDetails.investment.amountInvested);
      } else {
        this.$router.push('search-projects');
      }
    },    
  },
}
</script>

<style>

</style>
